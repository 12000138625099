.container {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
    width: 100%;

    // &--large {
    //     max-width: 1600px;
    // }

    // &--medium {
    //     max-width: 1240px;
    // }

    // &--small {
    //     max-width: 700px;
    // }
}

@media only screen and (max-width: #{$max-width}) {
    .container {
        max-width: 425px;
    }
}
