.team-members {
    &__grid {
        @include grid-with-gutter(3rem);
        justify-content: center;
    }

    .team-member {
        @include column-with-gutter(4, 3rem);

        &__inner {
            margin: 0 auto;
            max-width: 200px;

            > *:not(:last-child) {
                display: block;
                margin-bottom: 1rem;
            }
        }

        img {
            filter: grayscale(100%);
        }

        h4 {
            font-family: $heading-font-stack;
        }

        a {
            display: block;
            font-weight: bold;
            margin-left: 15px;
            position: relative;
            text-align: left;

            span {
                display: inline-block;
                transition: transform $default-transition-timing;
            }

            &::before {
                @include background-cover();
                @include vertical-center();
                background-image: url("./images/triangle-orange-right.png");
                content: "";
                display: block;
                height: 10px;
                left: -15px;
                width: 10px;
            }

            &:hover span {
                transform: translateX(5px);
            }
        }
    }
}

@media only screen and (max-width: #{$max-width}) {
    .team-members {
        &__grid {
            @include grid-with-gutter(1rem);
        }

        .team-member {
            @include column-with-gutter(12, 1rem);
        }
    }
}
