.banner {
    @include background-cover();
    align-items: center;
    background-image: url("./images/header-background.png");
    display: flex;
    padding-bottom: 8rem;
    padding-top: 4rem;
    position: relative;

    h1 {
        font-family: $heading-font-stack;
        font-size: 60px;
    }

    p {
        margin: 0;
    }

    img {
        margin-bottom: 30px;
        max-width: 100px;
    }

    // &::before,
    // &::after {
    //     background-color: $saffron-mango;
    //     content: "";
    //     height: 100%;
    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%) skew(-30deg);
    // }

    // &::before {
    //     right: -15%;
    //     width: 30%;
    // }

    // &::after {
    //     left: -15%;
    //     width: 30%;
    // }

    // &__images {
    //     .box,
    //     .triangle-blue,
    //     .triangle-green,
    //     .triangle-orange-2,
    //     .triangle-orange,
    //     .triangle-white {
    //         position: absolute;
    //         z-index: 5;
    //     }

    //     .box {
    //         bottom: 1rem;
    //         right: 0;
    //         max-width: 10rem;
    //     }

    //     .triangle-blue {
    //         bottom: -1.5rem;
    //         left: 20%;
    //         max-width: 3rem;
    //     }

    //     .triangle-green {
    //         bottom: 50%;
    //         left: 20%;
    //         max-width: 2rem;
    //     }

    //     .triangle-orange {
    //         bottom: -3rem;
    //         right: 2.5rem;
    //         max-width: 6rem;
    //     }

    //     .triangle-orange-2 {
    //         bottom: -4rem;
    //         left: 4rem;
    //         max-width: 2rem;
    //     }

    //     .triangle-white {
    //         bottom: 50%;
    //         left: 5%;
    //         max-width: 5rem;
    //     }
    // }

    .container {
        position: relative;
        text-align: center;
        z-index: 10;
    }
}

@media only screen and (max-width: #{$max-width}) {
    .banner {
        background-image: url("./images/header-background-mobile.png");
    }
}
