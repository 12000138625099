// Global
$body-font-stack: "Roboto", sans-serif;
$heading-font-stack: "DM Serif Display", serif;
$section-margin: 4rem;
$section-margin-mobile: 2rem;

$gutter-width: 1rem;

$default-transition-timing: 0.3s;
$default-delay-timing: 0.2s;

// Colours
$black: #000;
$white: #fff;
$saffron: #f7bc3e;
$saffron-mango: #f9c657;
$tango: #ee772d;
$dove-gray: #707070;
$crusta: #f8742e;
$iron: #d2d3d3;

// Breakpoint sizes
$min-width: 320px;
$max-width: 800px;
$min-font: 14px;
$max-font: 16px;
