button,
input,
select,
textarea {
    border-radius: 0;
    color: inherit;
    font: inherit;
    padding: 0;

    &[readonly] {
        cursor: not-allowed;
    }
}

.standard-form,
.hbspt-form {
    .hs-form {
        @include list-reset();
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .form-row,
    .hs-form-field {
        margin-bottom: 1.5rem;
        width: calc(50% - #{$gutter-width});

        label:not(.hs-error-msg) {
            @include visually-hidden();
        }

        input,
        select,
        textarea {
            background-color: $white;
            border: none;
            border-bottom: 1px solid $iron;
            color: $black;
            font-family: $body-font-stack;

            &:not([type="checkbox"]):not([type="radio"]) {
                padding: 0.4rem 0;
                width: 100%;
            }
        }

        .select {
            position: relative;

            &::before {
                @include background-cover();
                @include vertical-center();
                background-image: url('./images/triangle-black.png');
                content: "";
                pointer-events: none;
                right: 1rem;
                height: 10px;
                width: 10px;
            }
        }
    }

    .hs-submit {
        width: 100%;

        .actions {
            text-align: center;
        }
    }
}
