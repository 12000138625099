.steps {
    .step-indicator {
        &__dots {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
        
        &__dot {
            background-color: $white;
            border: 1px solid $dove-gray;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            margin-left: 5px;
            margin-right: 5px;

            &.is-active {
                background-color: $crusta;
                border-color: $crusta;
            }
        }

        h3 {
            font-weight: bold;
        }
    }

    .step {
        display: none;

        &.is-active {
            display: block;
        }
    }
}
