button,
input,
select,
textarea {
    border-radius: 0;
    color: inherit;
    font: inherit;
    padding: 0;

    &[readonly] {
        cursor: not-allowed;
    }
}

input[type="search"] {
    appearance: none;
}

select {
    background-color: transparent;
    display: block;
    height: 2.8rem;
    line-height: 2.8rem;
    width: 100%;
}

.standard-form {
    text-align: left;

    .fields {
        @include list-reset;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    label {
        display: block;
        font-size: 0.9rem;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
    }

    button:not(.browser-default):not(.button),
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
    select,
    textarea {
        background-color: transparent;
        border: 1px solid $black;
        border-radius: 0;
        color: $black;
        font: inherit;
        height: 2.8rem;
        line-height: 2.8rem;
        padding: 0 1rem;
        width: 100%;
    }

    textarea {
        display: block;
        min-height: 8rem;
    }

    .form-row {
        margin: 0;
        position: relative;
        width: 100%;

        .hide-label {
            @include visually-hidden;
        }

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &--half {
            width: calc(50% - 0.5rem);
        }
    }

    .checkbox,
    .radio {
        min-height: 2rem;

        input[type="checkbox"],
        input[type="radio"] {
            @include visually-hidden;

            &:checked + label::after,
            &:checked + span::after {
                display: block;
            }

            + label,
            + span {
                display: block;
                margin-left: 1rem;
                padding: 0 1rem;
                position: relative;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                }

                &::before {
                    background-color: transparent;
                    border: 1px solid $black;
                    display: block;
                    height: 1.5rem;
                    left: -1rem;
                    width: 1.5rem;
                }

                &::after {
                    border: 1px solid $black;
                    border-left: 0;
                    border-top: 0;
                    display: none;
                    height: 1rem;
                    left: -0.4rem;
                    position: absolute;
                    top: 0.1rem;
                    transform: rotate(45deg);
                    width: 0.4rem;
                }
            }
        }

        a {
            @include link-style();
        }

        :disabled {
            &,
            & + label {
                cursor: not-allowed;
            }

            & + label {
                opacity: 0.3;
            }
        }
    }
}
