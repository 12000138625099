.button,
.hs-button {
    background-color: $tango;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    min-width: 140px;
    opacity: 1;
    padding: 0.4rem .6rem;
    transition: background-color $default-transition-timing, color $default-transition-timing;

    &:hover {
        background-color: $black;
        color: $tango;
    }

    &.is-hidden {
        @include visually-hidden();
    }
}
