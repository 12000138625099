/*!
    Theme URI: https://www.proagrica.co.uk/
    Theme Name: Proagrica Theme
    Description: Custom theme
    Author: Proagrica
    Text Domain: Proagrica
    Version: 1.0
!*/

@charset "UTF-8";

$image-path: './images' !default;

@import 'settings/variables';

@import 'tools/grid',
        'tools/mixins',
        'tools/utilities',
        'tools/wordpress-core';

@import 'globals/reset',
        'globals/setup',
        'globals/buttons',
        'globals/forms';

@import 'elements/elements',
        'elements/forms';

@import 'objects/wrappers',
        'objects/content-utilities';

@import 'components/site-header',
        'components/site-footer',
        'components/user-content',
        'components/banner',
        'components/steps',
        'components/team-members';

@import 'tools/mediaqueries/utilities-mediaqueries';
